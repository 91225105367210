/* You can add global styles to this file, and also import other style files */

/* Добавим отсупов побольше */
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
    6: (
      $spacer * 4.5,
    ),
    7: (
      $spacer * 6,
    ),
  ),
  $spacers
);
/* Добавим отсупов побольше END*/

$link-hover-decoration: none;

/*Bootrstrap optional*/
@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";
@import "assets/css/material-fonts.css";

/*Materal UI*/
@import "mat-theme.scss";
mat-sidenav-container,
mat-sidenav-content,
mat-sidenav {
  height: 100%;
}
